<template>
  <div>
    <maca-modal
      titulo="Nuevo turno de retiro"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="130px"
      >
        <el-steps :space="200" :active="active" simple>
          <el-step title="Ubicación" icon="el-icon-location"></el-step>
          <el-step title="Fecha" icon="el-icon-date"></el-step>
          <!--  <el-step title="Técnicos" icon="el-icon-bicycle"></el-step> -->
        </el-steps>
        <!-- UBICACION -->
        <div v-show="active == 0" style="padding: 30px">
          <el-form-item label="Provincia" prop="area">
            <maca-select-box
              ref="selectBoxArea"
              v-model="form.area"
              :url="urlArea"
              itemLabel="descripcion"
              @change="seleccionarArea"
            ></maca-select-box>
          </el-form-item>
          <el-form-item
            v-show="form.area != null"
            label="Localidad"
            prop="subarea"
          >
            <maca-select-box
              ref="selectBoxSubarea"
              :necesitaParams="true"
              :getParams="getParamsSubarea"
              v-model="form.subarea"
              :url="urlSubarea"
              itemLabel="descripcion"
              @change="seleccionarSubarea"
            ></maca-select-box>
          </el-form-item>
          <el-form-item
            v-if="form.subarea != null"
            label="Barrios"
            prop="seccion"
          >
            <maca-select-box
              ref="selectBoxSeccion"
              :necesitaParams="true"
              :getParams="getParamsSeccion"
              v-model="form.seccion"
              :url="urlSeccion"
              itemLabel="descripcion"
              :multiple="true"
            ></maca-select-box>
          </el-form-item>
          <el-form-item v-if="form.seccion" style="text-align: right">
            <el-button type="primary" @click="active = 1">Continuar</el-button>
          </el-form-item>
        </div>
        <!-- FECHA -->
        <div v-show="active == 1" style="padding: 30px">
          <el-form-item v-show="form.subarea != null" label="Fecha">
            <el-date-picker
              v-model="form.fecha"
              type="daterange"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              unlink-panels
              range-separator="a"
              start-placeholder="Fecha desde"
              end-placeholder="Fecha hasta"
              :picker-options="pickerOptions"
              @change="seleccionarFecha"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="Horario"
            prop="horaInicio"
            v-if="form.fecha != null"
          >
            <el-time-select
              placeholder="Hora de Inicio"
              v-model="form.horaInicio"
              :picker-options="{
                start: '08:00',
                step: '00:30',
                end: '19:30',
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="Hora de fin"
              v-model="form.horaFin"
              :picker-options="{
                start: '08:30',
                step: '00:30',
                end: '20:00',
                minTime: form.horaInicio,
              }"
              @change="seleccionarTecnico()"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="active = 0">Atrás</el-button>
            <maca-boton-guardar
              :onSubmit="onSubmit"
              v-if="form.horaFin"
            ></maca-boton-guardar>
          </el-form-item>
        </div>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        horaInicio: null,
        horaFin: null,
        fecha: null,
        area: null,
        subarea: null,
        tecnico: null,
        seccion: null,
      },
      active: 0,
      urlArea: "/area/obtenerTodos",
      urlSubarea: "/subarea/obtenerTodos",
      urlUserTecnico: "/user/obtenerTodos",
      urlSeccion: "/seccion/obtenerTodos",
      impedirClose: false,
      persona: null,

      urlRol: "/user/obtenerTodosSelectRoles",

      //opciones del rango de fechas
      pickerOptions: {
        shortcuts: [
          {
            text: "Hoy",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Una semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Un mes",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      formRules: {
        fecha: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        horaInicio: [
          {
            required: true,
            message: "Por favor introduzca el Horario.",
            trigger: "change",
          },
        ],
        tecnico: [
          {
            required: true,
            message: "Por favor seleccione algún técnico.",
            trigger: "change",
          },
        ],
        area: [
          {
            required: true,
            message: "Por favor seleccione area.",
            trigger: "change",
          },
        ],
        subarea: [
          {
            required: true,
            message: "Por favor seleccione subarea.",
            trigger: "change",
          },
        ],
        seccion: [
          {
            required: true,
            message: "Por favor seleccione al menos un barrio.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.form.fecha = null;
        this.form.horaFin = null;
        this.form.horaInicio = null;
        this.form.tecnico = null;
        this.form.area = null;
        this.form.subarea = null;
        this.active = 0;
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    getParamsSubarea() {
      if (this.form.area) {
        let params = {
          areaID: this.form.area.id,
        };
        return params;
      }
      return {};
    },
    getParamsSeccion() {
      if (this.form.subarea) {
        let params = {
          subareaID: this.form.subarea.id,
        };
        return params;
      }
      return {};
    },
    getParamsTecnico() {
      let params = {
        rolID: 5,
      };
      return params;
    },
    seleccionarArea() {
      this.form.subarea = null;
      this.form.seccion = null;
      this.form.fecha = null;
      this.form.turno = null;
      if (this.form.area != null) {
        this.$refs.selectBoxSubarea.recargar();
      }
    },
    seleccionarSubarea() {
      this.form.seccion = null;
      this.form.fecha = null;
      this.form.turno = null;
      if (this.form.subarea != null) {
        this.$refs.selectBoxSeccion.recargar();
      }
    },
    seleccionarFecha() {
      this.form.tecnico = null;
      if (this.form.fecha != null && this.form.subarea != null) {
      }
    },
    seleccionarTecnico() {
      //this.$refs.selectBoxTecnico.recargar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    crearDetalleTecnico() {
      let detalleTecnico = [];
      this.form.tecnico.forEach((tecnico) => {
        detalleTecnico.push({ tecnicoID: tecnico.id });
      });
      return detalleTecnico;
    },
    crearDetalleSeccion() {
      let detalleSeccion = [];
      this.form.seccion.forEach((seccion) => {
        detalleSeccion.push({ seccionID: seccion.id });
      });
      return detalleSeccion;
    },
    async postApi() {
      // Hacer Post
      let params = {
        primerFecha: this.form.fecha[0],
        ultimaFecha: this.form.fecha[1],
        horaInicio: this.form.horaInicio,
        horaFin: this.form.horaFin,
        subareaID: this.form.subarea.id,
        detalleSeccion: JSON.stringify(this.crearDetalleSeccion()),
      };

      let respuestaApi = await this.$maca.api.post(
        "/rangoRetiro/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Turno de retiro creados con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>
