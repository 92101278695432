<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-card :body-style="{ padding: '25px' }">
          <div slot="header">
            <div style="margin-left: 0px; display: flex">
              <div class="select2">
                <el-select
                  v-model="selectTipoBusqueda"
                  slot="prepend"
                  style="width: 140px"
                >
                  <el-option label="Fecha" :value="1"></el-option>
                  <el-option label="Localidad" :value="2"></el-option>
                  <el-option label="Barrio" :value="3"></el-option>
                </el-select>
              </div>
              <div class="buscador2">
                <!-- Fecha -->
                <el-date-picker
                  v-show="selectTipoBusqueda == 1"
                  ref="datepicker"
                  v-model="fecha"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Seleccionar fecha"
                  @change="actualizarTablaFuncion()"
                ></el-date-picker>
                <!-- Localidad -->
                <maca-select-box
                  v-show="selectTipoBusqueda == 2"
                  :url="urlSubarea"
                  v-model="subarea"
                  :clearable="true"
                  itemLabel="descripcion"
                  placeholder="Seleccione localidad"
                  @change="actualizarTablaFuncion()"
                >
                </maca-select-box>
                <!-- Barrio -->
                <maca-select-box
                  v-show="selectTipoBusqueda == 3"
                  :url="urlSeccion"
                  v-model="seccion"
                  :clearable="true"
                  itemLabel="descripcion"
                  placeholder="Seleccione barrio"
                  @change="actualizarTablaFuncion()"
                  :necesitaParams="true"
                  :getParams="getParamsSeccion"
                  :permitirInput="true"
                >
                </maca-select-box>
              </div>
            </div>
            <el-button
              class="botonmasIcono"
              icon="el-icon-plus"
              type="primary"
              round
              style="margin-right: 0px"
              @click="$refs.modalNuevo.abrir()"
              >Nuevo</el-button
            >
          </div>

          <div>
            <maca-datatable
              :url="urlTabla"
              :params="paramsTabla"
              :actualizar.sync="actualizarTabla"
              :bloquear.sync="bloquearTabla"
              @tabla-cargada="(datos) => cargarDatosEnCalendario(datos)"
            >
              <el-table-column
                :min-width="120"
                label="
          Fecha"
              >
                <template slot-scope="props">
                  <span
                    v-text="$maca.common.formatearFecha(props.row.fecha)"
                  ></span>
                </template>
              </el-table-column>

              <el-table-column :min-width="120" label="Rango horario">
                <template slot-scope="props">
                  <span
                    v-text="
                      $maca.common.formatearHora(props.row.horaInicio) +
                      ' - ' +
                      $maca.common.formatearHora(props.row.horaFin)
                    "
                  ></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Provincia">
                <template slot-scope="props">
                  <div v-if="props.row.detalleSubarea != null">
                    <div v-if="props.row.detalleSubarea.detalleArea != null">
                      <span
                        v-text="
                          props.row.detalleSubarea.detalleArea.descripcion
                        "
                      ></span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Localidad">
                <template slot-scope="props">
                  <div v-if="props.row.detalleSubarea != null">
                    <span v-text="props.row.detalleSubarea.descripcion"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Barrios">
                <template slot-scope="props">
                  <p
                    v-for="(item, index) in props.row.detalleRangoRetiroSeccion"
                    :key="index"
                  >
                    {{ item.detalleSeccion.descripcion }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="Borrar" width="70">
                <template slot-scope="props">
                  <el-button
                    type="danger"
                    @click="eliminar(props.row.id)"
                    circle
                    :disabled="!$store.getters.tienePermiso('B_USE')"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <h4 style="color: #909399">Turnos de retiro disponibles</h4>
          <vc-date-picker
            :rows="2"
            is-expanded
            :attributes="attrs"
            v-model="fecha"
          ></vc-date-picker>
        </el-card>
      </el-col>
    </el-row>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "turno-retiro",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      //calendario
      attrs: [],
      //tabla
      urlTabla: "/rangoRetiro/obtenerTodos",
      urlSubarea: "/subarea/obtenerTodos",
      urlSeccion: "/seccion/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      fecha: null,
      subarea: null,
      seccion: null,
      selectTipoBusqueda: 1,
    };
  },
  methods: {
    getParamsSeccion(query) {
      let params = { descripcion: query };
      return params;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el turno de retiro."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/rangoRetiro/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Turno de retiro borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    actualizarTablaFuncion() {
      if (this.selectTipoBusqueda == 1) {
        if (this.fecha) this.paramsTabla = { fecha: this.fecha };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 2) {
        if (this.subarea) this.paramsTabla = { subareaID: this.subarea.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 3) {
        if (this.seccion) this.paramsTabla = { seccionID: this.seccion.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      }
    },
    cargarDatosEnCalendario(fechas) {
      this.attrs = [];
      fechas.datos.forEach((fecha) => {
        let item = {
          dates: fecha.fecha.replace(/-/g, "/"),
          highlight: {
            color: "purple",
            fillMode: "light",
          },
        };

        this.attrs.push(item);
      });
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
    fecha: function (n, o) {
      this.paramsTabla.fecha = this.$maca.common.formatearFechaTipoApi(
        this.fecha
      );
      this.actualizarTabla = true;
    },
  },
};
</script>

